import axios from 'axios';
import { IAPI } from './api';
import { ICategory } from '../models/category.model';
import { IGame, IEvent } from '../models/game.model';
import { StdRes, success } from '../models/protocol-base.model';
import { HttpDevice, IDevice, WS } from './comm/device';

const URLs = {
  ARAN: 'http://localhost:8090/api/v1',
  PROD_FROM_LOCAL: 'http://132.145.224.68:8090',
  PROD: '/api/v1',
};

const URL = URLs.PROD;

// axios.defaults.baseURL = URL;

class RealAPI implements IAPI {
  game?: IGame;
  handler?: (event: IEvent) => void;
  client?: IDevice;

  listCategories(): StdRes<ICategory[]> {
    return axios.get<StdRes<ICategory[]>>(URL + '/categories').then();
  }

  listGames(): StdRes<IGame[]> {
    return axios.get<any>(URL + '/games').then((response: any) => {
      return success(response.data);
    });
  }

  createGame(category: string, name: string): StdRes<IGame> {
    return axios
      .post<any>(URL + '/game', { category, name })
      .then((response: any) => {
        const data = response.data;
        const player = {
          id: data?.player?.id,
          name: data?.player?.name,
          words: [],
        };

        this.game = {
          id: data?.game?.id,
          categoryId: data?.game?.category,
          is_ready: false,
          is_started: false,
          name: data?.game?.name,
          round: 0,
          player,
          players: [player],
        };

        return success(this.game);
      });
  }

  joinGame(id: string, playerName: string): StdRes<IGame> {
    return axios
      .post<StdRes<IGame>>(`${URL}/game/${id}`, { name: playerName })
      .then((response: any) => {
        const data = response.data;
        const player = {
          id: data?.player?.id,
          name: data?.player?.name,
          words: [],
        };

        this.game = {
          id: data?.game?.id,
          categoryId: data?.game?.category,
          is_ready: data?.game?.is_ready,
          is_started: data?.game?.is_strated,
          name: data?.game?.name,
          round: 0,
          player,
          players: [...data?.game?.players, player],
        };
        return success(this.game);
      });
  }

  leaveGame(): void {
    this.sendGameEvent('leave-game');
  }

  startGame(): void {
    this.sendGameEvent('start-game');
  }

  closeGame(): void {
    throw new Error('Method not implemented.');
  }

  sendMessage(message: string): void {
    this.sendGameEvent('chat-message', { message });
  }

  votePlayer(id: string): void {
    this.sendGameEvent('vote-player', { id });
  }

  guessTarget(target: string): void {
    this.sendGameEvent('guess-target', { target });
  }

  submitWord(word: string): void {
    this.sendGameEvent('submit-word', { word });
  }

  getEvents(handler: (event: IEvent) => void) {
    if (!this.game?.id || !this.game.player.id) {
      console.error('no game or player id');
      return;
    }
    this.handler = handler;
    this.client = new WS();
    this.client.init(
      URL,
      this.game?.id,
      this.game?.player.id,
      this.handler,
      err => {
        console.error('failed to use websocket for game', err);
        alert(
          'failed to use websocket! your fucking iphone forces us to degrade to http polling' +
            `${err}`,
        );

        if (!this.game?.id || !this.game.player.id) {
          console.error('no game or player id');
          return;
        }

        this.client = new HttpDevice();
        this.client.init(
          URL,
          this.game?.id,
          this.game?.player.id,
          handler,
          err2 => {
            console.error('http poll fallback failed', err2);
            alert('this also failed. no game for you');
          },
        );
      },
    );
    // this.client = new WebSocketClient(URL + '/game');
    // // this.client = new WebSocketClient('game');
    // this.client.onMessage(event => {
    //   const parsedEvent = JSON.parse(event.data) as IEvent;
    //   this.handler && this.handler(parsedEvent);
    // });

    // this.client.onError(err => {
    //   console.error('failed to use websocket for game', err);
    //   alert(
    //     'failed to use websocket! your fucking iphone forces us to degrade to http polling' +
    //       `${err}`,
    //   );
    // });
  }

  sendGameEvent(type: string, event: Record<string, string> = {}) {
    this.client?.send(
      JSON.stringify({
        type,
        event,
        player_id: this.game?.player.id,
        game_id: this.game?.id,
      }),
    );
  }
}

export const realAPI: IAPI = new RealAPI();
